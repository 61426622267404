import React, { useState } from 'react';
import { MdMenu, MdClose } from 'react-icons/md';
import NavItem from './NavItem';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-900 p-2 fixed top-0 w-full z-10 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex flex-row items-center pl-4">
          <button
            className="text-white mr-4 md:hidden focus:outline-none"
            onClick={toggleMenu}
            aria-label={isOpen ? 'Cerrar menú' : 'Abrir menú'}
          >
            {isOpen ? <MdClose size={30} /> : <MdMenu size={30} />}
          </button>
          <div>
            <NavItem to="/" exact={true} onClick={closeMenu}>
              <span className="text-lg lg:text-2xl font-bold text-white">Menu <span className='italic font-light text-amber-600'>PLUSS</span></span>
            </NavItem>
          </div>
        </div>
        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-90 md:bg-transparent md:static md:inset-auto md:hidden lg:flex md:items-center transition-transform duration-300 ease-in-out transform mt-16 lg:mt-0 border-t border-amber-600 lg:border-none ${isOpen ? 'translate-x-0' : 'translate-x-full md:translate-x-0'
            }`}
          role="menu"
        >
          <div className="flex flex-col md:flex-row md:items-center md:space-x-2 p-4 md:p-0">
            <NavItem to="/" exact={true} onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Inicio</NavItem>
            <NavItem to="/hamburguesas" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Hamburguesas</NavItem>
            <NavItem to="/perros" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Perros</NavItem>
            <NavItem to="/pepitos" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Pepitos</NavItem>
            <NavItem to="/patacon" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Patacón</NavItem>
            <NavItem to="/shawarma" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Shawarma</NavItem>
            <NavItem to="/salchipapas" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Salchipapas</NavItem>
            <NavItem to="/picadas" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Picadas</NavItem>
            <NavItem to="/asados" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Asados</NavItem>
            <NavItem to="/bebidas" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Bebidas</NavItem>
            <NavItem to="/cart" onClick={closeMenu} className="text-white text-lg py-2 md:py-0">Carrito</NavItem>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
