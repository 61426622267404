import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/CartContext';
import { SiWhatsapp } from "react-icons/si";
import { HiOutlineTrash } from "react-icons/hi2";
import { SlArrowLeft } from "react-icons/sl";
import { RiAddLargeFill, RiSubtractFill } from "react-icons/ri";
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '90vh', // Set a maximum height
        overflowY: 'auto' // Enable vertical scrolling
    }
};

Modal.setAppElement('#root');

const Cart = () => {
    const { cartItems, orderId, removeFromCart, clearCart, increaseQuantity, decreaseQuantity } = useContext(CartContext);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [observations, setObservations] = useState('');
    const [total, setTotal] = useState(0);
    const [orderDateTime, setOrderDateTime] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Estado para manejar la carga del pedido

    useEffect(() => {
        const totalAmount = cartItems.reduce((sum, item) => sum + item.precio * item.quantity, 0);
        setTotal(totalAmount);
        const now = new Date();
        const dateTimeString = now.toLocaleString('es-CO', {
            dateStyle: 'short',
            timeStyle: 'short'
        });
        setOrderDateTime(dateTimeString);
    }, [cartItems]);

    const formatCurrency = (value) => {
        return value.toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP',
            maximumFractionDigits: 0
        });
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const sendToWhatsApp = () => {
        setLoading(true); // Iniciar carga
        const orderText = `Quiero realizar este pedido desde la APP:\n\nPedido ID: ${orderId}\nFecha y Hora: ${orderDateTime}\nNombre: ${name}\nDirección: ${address}\nTeléfono: ${phone}\nObservaciones: ${observations}\n\nProductos:\n` +
            cartItems.map(item => `*${item.nombre}* \n- ${item.ingredientes} \n- (x ${item.quantity}) \n- Precio: ${formatCurrency(item.precio * item.quantity)}`).join('\n') +
            `\n\nTotal: ${formatCurrency(total)}`;
        const url = `https://api.whatsapp.com/send?phone=573182333790&text=Hola, ${encodeURIComponent(orderText)}`;
        window.open(url, '_blank');

        setLoading(false); // Finalizar carga
        closeModal(); // Cerrar el modal después de enviar el pedido
    };

    const clearFields = () => {
        setName('');
        setAddress('');
        setPhone('');
        setObservations('');
    };

    const handleClearCart = () => {
        clearCart();
        clearFields();
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        setPhone(newPhone);
        if (!validatePhone(newPhone)) {
            setPhoneError('Número de teléfono inválido. Debe contener 10 dígitos.');
        } else {
            setPhoneError('');
        }
    };

    const isFormValid = name.trim() !== '' && address.trim() !== '' && phone.trim() !== '' && phoneError === '';

    return (
        <div className="p-8 min-h-screen max-w-7xl container mx-auto shadow-md mt-24 mb-32 lg:px-24">
            <h1 className="text-3xl font-bold mb-4">Carrito de Compras</h1>
            <div className='flex flex-col lg:flex-row gap-2 items-center justify-between rounded-lg shadow text-gray-500 border px-4 py-2 my-4'>
                <div className="text-md">
                    Pedido ID: {orderId}
                </div>
                <div className="text-md">
                    Fecha y Hora: {orderDateTime}
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 py-4 my-4'>
                <div>
                    <div className='flex gap-1'>
                        <label className="block font-semibold">Nombre:</label>
                        {name.trim() === '' && <p className="text-red-500 text-sm mt-0 mb-2 flex items-center">
                            Campo requerido</p>}
                        <span className='font-semibold text-red-500 mr-2'>*</span>
                    </div>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <div className='flex gap-1'>
                        <label className="block mb-1 font-semibold">Dirección:</label>
                        {address.trim() === '' && <p className="text-red-500 text-sm mt-0 mb-2 flex items-center">
                            Campo requerido</p>}
                        <span className='font-semibold text-red-500 mr-2'>*</span>
                    </div>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <div className='flex gap-1'>
                        <label className="block mb-1 font-semibold">Teléfono:</label>
                        {phone.trim() === '' && <p className="text-red-500 text-sm mt-0 mb-2 flex items-center">
                            Campo requerido</p>}
                        <span className='font-semibold text-red-500 mr-2'>*</span>
                    </div>
                    <input
                        type="text"
                        value={phone}
                        onChange={handlePhoneChange}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        required
                    />
                    {phoneError && <p className="text-red-500 text-sm mt-1">{phoneError}</p>}
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Observaciones:</label>
                    <textarea
                        value={observations}
                        onChange={(e) => setObservations(e.target.value)}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
            </div>
            <ul className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {cartItems.map(item => (
                    <li key={item.uuid} className="p-4 border rounded shadow-md">
                        <div>
                            <h2 className="text-xl font-semibold">{item.nombre}</h2>
                            <p className='text-sm'>{item.ingredientes}</p>
                            <p className='text-sm'>Precio: {formatCurrency(item.precio)} X {item.quantity}</p>
                            <p className='text-md'><span className='font-semibold'>Subtotal: </span>{formatCurrency(item.precio * item.quantity)}</p>
                            <div className="flex mt-4 gap-2">
                                <button onClick={() => decreaseQuantity(item.uuid)} className="border border-red-500 text-red-500 px-3 py-1 rounded">
                                    <RiSubtractFill size={18} />
                                </button>
                                <button onClick={() => increaseQuantity(item.uuid)} className="border border-green-500 text-green-500 px-3 py-1 rounded">
                                    <RiAddLargeFill size={18} />
                                </button>
                                <button onClick={() => removeFromCart(item.uuid)} className="bg-red-500 text-white px-4 py-1 rounded flex items-center space-x-2">
                                    <HiOutlineTrash size={18} />
                                    <div>Borrar</div>
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <div className='flex flex-col md:flex-row justify-between border-t-2 pt-4 gap-4'>
                <div className="text-xl font-semibold px-6 py-2 border rounded-lg shadow text-end">
                    Total: {formatCurrency(total)}
                </div>
                <div className='flex space-x-3 justify-end'>
                    <button
                        onClick={openModal}
                        className={`bg-green-500 text-white px-4 py-2 rounded flex items-center space-x-2 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!isFormValid}
                    >
                        <SiWhatsapp size={20} />
                        <div>Revisar Pedido</div>
                    </button>
                    <button onClick={handleClearCart} className="bg-red-500 text-white px-4 py-2 rounded flex items-center space-x-2">
                        <HiOutlineTrash size={20} />
                        <div>Vaciar</div>
                    </button>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Resumen del Pedido"
            >
                <h2 className="text-2xl font-bold border-b px-4 py-2">Resumen del Pedido</h2>
                <div className="my-4 p-4 border rounded-lg shadow">
                    <p><strong>Pedido ID:</strong> {orderId}</p>
                    <p><strong>Fecha y Hora:</strong> {orderDateTime}</p>
                    <p><strong>Nombre:</strong> {name}</p>
                    <p><strong>Dirección:</strong> {address}</p>
                    <p><strong>Teléfono:</strong> {phone}</p>
                    <p><strong>Observaciones:</strong> {observations}</p>
                </div>
                <div className="overflow-y-auto max-h-64 bg-gray-50 mb-4 border-2 rounded-lg shadow-sm p-2">
                    <ul>
                        {cartItems.map(item => (
                            <li key={item.uuid} className="mb-4 p-4 border rounded shadow-md bg-white">
                                <div className='flex gap-2 font-semibold'>
                                    <h2>{item.nombre}</h2>
                                    <p className='font-normal'>(Cant. {item.quantity})</p>
                                </div>
                                <p>Precio: {formatCurrency(item.precio)} x {item.quantity}</p>
                                <p><span className='font-semibold'>Subtotal: </span>{formatCurrency(item.precio * item.quantity)}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="text-xl font-semibold mb-4 shadow p-4 border rounded-lg text-end">
                    Total: {formatCurrency(total)}
                </div>
                <div className='flex flex-col lg:flex-row justify-between gap-2'>
                    <button onClick={closeModal} className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2">
                        <SlArrowLeft size={20} />
                        <div>Regresar</div>
                    </button>
                    <button
                        onClick={sendToWhatsApp}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
                        disabled={loading}
                    >
                        <SiWhatsapp size={20} />
                        <div>{loading ? 'Enviando...' : 'Confirmar y Enviar'}</div>
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default Cart;
