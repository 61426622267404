import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { RiAddLargeFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import { SlArrowLeft } from "react-icons/sl";

const ItemList = ({ title, jsonFile }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useContext(CartContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true); // Iniciar carga
      try {
        const response = await fetch(`/data/${jsonFile}`, {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        const data = await response.json();
        setItems(data.items);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Finalizar carga
      }
    };

    fetchItems();

    return () => {
      setItems([]);
      setShowModal(false);
      setSelectedItem(null);
    };
  }, [jsonFile]);

  const handleImageError = (e) => {
    e.target.src = '/logo512.png';
  };

  const handleAddToCart = (item) => {
    addToCart(item);
    setSelectedItem(item);
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 500);
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="p-4 lg:p-8 min-h-screen max-w-7xl container mx-auto mt-16 mb-32 border shadow-xl">
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
            <FaCheck size={30} className='mb-4 w-24 h-24 rounded-full bg-orange-500 text-white' />
            <p className="text-lg font-normal">Producto añadido al carrito</p>
            <p className="text-lg font-normal"><span className='font-semibold'>{selectedItem && selectedItem.nombre}</span></p>
          </div>
        </div>
      )}
      <div className='py-4 mt-4 border-b mb-4 flex flex-col lg:flex-row gap-3 items-center justify-between'>
        <h1 className="text-3xl lg:text-4xl font-extrabold">{title}</h1>
        <button onClick={handleGoHome} className="bg-gray-800 hover:bg-gray-600 text-white px-6 py-2 mb-4 flex items-center gap-4 rounded-full">
          <SlArrowLeft size={18} />
          <p className='font-bold'>MENU</p>
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
        </div>
      ) : (
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {items.map(item => (
            <li key={item.uuid} className="bg-white border rounded-lg shadow-md space-x-3">
              <div className="w-full h-56 mb-2 bg-white shadow-sm rounded-t-lg overflow-hidden">
                <img
                  src={`/data/${item.foto}`}
                  alt={item.nombre}
                  onError={handleImageError}
                  className="w-full h-full object-contain"
                />
              </div>
              <div className='p-3 space-y-3 py-4'>
                <h2 className="text-xl font-semibold">{item.nombre}</h2>
                <p className='text-md '>{item.ingredientes}</p>
                <div className='flex flex-row items-center justify-between'>
                  <p className='text-xl font-semibold'>
                    Precio: ${item.precio.toLocaleString('es-CO')}
                  </p>
                  <button onClick={() => handleAddToCart(item)} className="bg-gray-700 hover:bg-gray-800 text-white px-4 py-2 rounded text-sm">
                    <RiAddLargeFill size={15} />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ItemList;
